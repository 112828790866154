import type { RouteLocationNormalizedLoaded, Router } from "vue-router";

type RouteName = "signup" | "plans" | "menu" | "checkout";

export default class RegFlowStep {
  name: RouteName;

  order: number;

  navigationGuard: () => boolean;

  constructor(name: RouteName, order: number, navigationGuard: () => boolean) {
    this.name = name;
    this.order = order;
    this.navigationGuard = navigationGuard;
  }

  isCurrent(route: RouteLocationNormalizedLoaded) {
    return this.getAssociatedRouteName() === route.name;
  }

  isComplete(route: RouteLocationNormalizedLoaded, hasPlan: boolean) {
    switch (this.name) {
      case "signup": {
        return route.name !== "onboarding-signup";
      }
      case "plans": {
        return (
          route.name !== "onboarding-plans" &&
          route.name !== "onboarding-signup" &&
          hasPlan
        );
      }
      case "menu": {
        return route.name === "onboarding-checkout";
      }
      default:
        return false;
    }
  }

  isUpcoming(route: RouteLocationNormalizedLoaded) {
    switch (this.name) {
      case "plans": {
        return route.name === "onboarding-signup";
      }
      case "menu": {
        return ["onboarding-signup", "plans"].includes(route.name as string);
      }
      case "checkout": {
        return route.name !== "onboarding-checkout";
      }
      default:
        return false;
    }
  }

  navigate(router: Router) {
    const current = router.currentRoute.value.name;
    const next = this.getAssociatedRouteName();
    if (
      next !== current &&
      (this.navigationGuard ? this.navigationGuard() : true)
    ) {
      navigateTo({
        name: next,
        query: router.currentRoute.value.query,
      });
    }
  }

  getAssociatedRouteName() {
    const mapping = {
      signup: "onboarding-signup",
      plans: "onboarding-plans",
      menu: "menus",
      checkout: "onboarding-checkout",
    };
    return mapping[this.name];
  }
}
